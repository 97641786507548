import { useSearchParams, Form } from "@remix-run/react";
import { Filter, filters, Option } from "~/constants/filters";
import { ActiveCount, ActiveJobFilters } from "~/types/Precompute";
import { useState, useRef, useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Dropdown } from "~/components/Dropdown";

interface FilterSectionProps {
  section: Filter;
  activeJobFilters?: ActiveJobFilters;
  precomputedActiveFilters?: ActiveJobFilters;
  selectedValues: string[];
  onChange: (value: string) => void;
  isActive: boolean;
}

function FilterSection({
  section,
  activeJobFilters,
  precomputedActiveFilters,
  selectedValues,
  onChange,
  isActive,
}: FilterSectionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (buttonRef.current?.contains(event.target as Node)) {
        return;
      }

      const dropdownMenus = document.querySelectorAll("[data-dropdown-menu]");
      for (const menu of dropdownMenus) {
        if (menu.contains(event.target as Node)) {
          return;
        }
      }

      setIsOpen(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  function getActiveFilter(filtersCount: ActiveCount[]) {
    return function (option: Option) {
      const res = filtersCount?.find(
        (activeFilter) => activeFilter.value === option.value,
      );
      return res;
    };
  }

  const activeFilters = getActiveJobFiltersBySectionId(
    activeJobFilters,
    section.id,
  );

  const precomputedFilters = getActiveJobFiltersBySectionId(
    precomputedActiveFilters,
    section.id,
  );

  let options = section.options;

  if (precomputedFilters) {
    options = options
      .filter(getActiveFilter(precomputedFilters))
      .map((option) => {
        let count: number | undefined;
        const activeFilter = getActiveFilter(
          activeFilters || precomputedFilters,
        )(option);

        const precomputedCount = getActiveFilter(precomputedFilters)(option);

        if (activeFilter) {
          count = activeFilter.count;
        }

        const countString = count || !isActive ? ` (${count || 0})` : "";

        return {
          ...option,
          label: `${option.label} ${countString}`,
          count,
          precomputedCount: precomputedCount?.count || 0,
        };
      })
      .sort((a, b) => {
        if (a.precomputedCount && b.precomputedCount) {
          return b.precomputedCount - a.precomputedCount;
        }
        return 0;
      });
  }

  if (section.id === "companies") {
    options = options.sort((a, b) => {
      return a.label < b.label ? -1 : 1;
    });
  }

  const renderOptions = (options: Option[]) => {
    if (section.groups) {
      return section.groups.map(
        ({ name: groupName, options: groupOptions }) => (
          <div key={groupName}>
            <div className="px-2 py-1.5 text-xs font-medium text-gray-500 border-b border-gray-700/50">
              {groupName}
            </div>
            <div className="p-2 space-y-1">
              {groupOptions.map((option) => (
                <label
                  key={option.value}
                  className="flex items-center p-2 hover:bg-gray-700/50 rounded-sm cursor-pointer transition-colors"
                >
                  <div className="relative flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedValues.includes(option.value)}
                      onChange={() => onChange(option.value)}
                      className="peer h-4 w-4 rounded border-gray-600 text-lime-500 focus:ring-lime-500/20 focus:ring-offset-0 bg-gray-700"
                    />
                    <span className="ml-2 text-sm text-gray-300 peer-checked:text-lime-300 transition-colors">
                      {option.label}
                    </span>
                  </div>
                </label>
              ))}
            </div>
          </div>
        ),
      );
    }

    return (
      <div className="p-2 space-y-1">
        {options.map((option) => (
          <label
            key={option.value}
            className="flex items-center p-2 hover:bg-gray-700/50 rounded-sm cursor-pointer transition-colors"
          >
            <div className="relative flex items-center">
              <input
                type="checkbox"
                checked={selectedValues.includes(option.value)}
                onChange={() => onChange(option.value)}
                className="peer h-4 w-4 rounded border-gray-600 text-lime-500 focus:ring-lime-500/20 focus:ring-offset-0 bg-gray-700"
              />
              <span className="ml-2 text-sm text-gray-300 peer-checked:text-lime-300 transition-colors">
                {option.label}
              </span>
            </div>
          </label>
        ))}
      </div>
    );
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full flex items-center justify-between px-3 py-2 rounded-md text-sm font-medium transition-all duration-200 
          ${
            selectedValues.length > 0
              ? "bg-lime-900/30 text-lime-300 hover:bg-lime-900/40"
              : "bg-gray-800 text-gray-400 hover:bg-gray-700"
          }`}
      >
        <span className="flex items-center gap-2">
          {section.name}
          {selectedValues.length > 0 && (
            <span className="text-xs bg-lime-900/50 text-lime-300 px-1.5 py-0.5 rounded">
              {selectedValues.length}
            </span>
          )}
        </span>
        <ChevronDownIcon
          className={`h-4 w-4 transition-transform duration-200 ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <Dropdown buttonRef={buttonRef}>
          <div
            data-dropdown-menu
            className="mt-1 bg-gray-800/95 backdrop-blur-sm rounded-md shadow-lg border border-gray-700/50 max-h-[300px] overflow-y-auto"
          >
            {renderOptions(options)}
          </div>
        </Dropdown>
      )}
    </div>
  );
}

interface FiltersProps {
  jobCount: number;
  activeJobFilters?: ActiveJobFilters;
  precomputedFilters?: ActiveJobFilters;
  alert?: React.ReactNode;
  authenticated?: boolean;
}

export function Filters({
  jobCount,
  activeJobFilters,
  precomputedFilters,
  alert,
  authenticated,
}: FiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFilterChange = (sectionId: string, value: string) => {
    const newParams = new URLSearchParams(searchParams);

    if (searchParams.getAll(sectionId).includes(value)) {
      const values = searchParams.getAll(sectionId).filter((v) => v !== value);
      newParams.delete(sectionId);
      values.forEach((v) => newParams.append(sectionId, v));
    } else {
      newParams.append(sectionId, value);
    }

    if (!newParams.get("init")) {
      newParams.set("init", "true");
    }

    newParams.delete("page");

    setSearchParams(newParams, { preventScrollReset: true });
  };

  const removeFilter = (sectionId: string, value: string) => {
    const newParams = new URLSearchParams(searchParams);
    const values = searchParams.getAll(sectionId).filter((v) => v !== value);
    newParams.delete(sectionId);
    values.forEach((v) => newParams.append(sectionId, v));

    if (!newParams.get("init")) {
      newParams.set("init", "true");
    }

    setSearchParams(newParams, { preventScrollReset: true });
  };

  // Get active filters for the ActiveFilters component
  const activeFilters: Record<string, Option[]> = {};
  filters.forEach((filter) => {
    const checked = searchParams.getAll(filter.id);
    if (checked.length) {
      activeFilters[filter.id] = filter.options.filter((option) =>
        checked.includes(option.value),
      );
    }
  });

  // Add reset filters function
  const resetFilters = () => {
    const newParams = new URLSearchParams();
    newParams.set("init", "true");
    setSearchParams(newParams, { preventScrollReset: true });
  };

  return (
    <div className="flex flex-col gap-4">
      {alert && <div className="mb-2">{alert}</div>}

      <div className="space-y-4">
        <Form
          method="GET"
          className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-4 shadow-lg border border-gray-700/50"
        >
          <div className="flex justify-between items-center mb-6 pb-3 border-b border-gray-700/50">
            <div className="flex items-baseline gap-3">
              <h3 className="text-sm font-medium text-gray-400">Filter by</h3>
              {Object.keys(activeFilters).length > 0 && (
                <>
                  <span className="text-xs text-gray-500">
                    {Object.values(activeFilters).flat().length} active
                  </span>
                  <button
                    type="button"
                    onClick={resetFilters}
                    className="text-xs text-gray-400 hover:text-gray-200 transition-colors"
                  >
                    Reset filters
                  </button>
                </>
              )}
            </div>
            <div className="text-sm text-gray-400">
              <span className="font-medium text-lime-300">{jobCount}</span> jobs
            </div>
          </div>

          <input type="hidden" name="init" value="true" />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
            {filters.map((section) => {
              if (
                !authenticated &&
                (section.id === "companies" || section.id === "techStack")
              )
                return null;
              return (
                <FilterSection
                  key={section.id}
                  section={section}
                  activeJobFilters={activeJobFilters}
                  precomputedActiveFilters={precomputedFilters}
                  selectedValues={searchParams.getAll(section.id)}
                  isActive={searchParams.has(section.id)}
                  onChange={(value) => handleFilterChange(section.id, value)}
                />
              );
            })}
          </div>
        </Form>

        {Object.keys(activeFilters).length > 0 && (
          <div className="flex flex-wrap gap-2">
            {Object.keys(activeFilters).map((filterId) =>
              activeFilters[filterId].map((activeFilter) => (
                <button
                  type="button"
                  onClick={() => removeFilter(filterId, activeFilter.value)}
                  key={activeFilter.value}
                  className="inline-flex items-center text-sm bg-gray-800/50 hover:bg-gray-700/50 text-gray-300 py-1.5 pl-3 pr-2 rounded-md transition-colors border border-gray-700/50 backdrop-blur-sm"
                >
                  <span>{activeFilter.label}</span>
                  <div className="ml-2 text-gray-500 hover:text-gray-300">
                    <svg
                      className="h-3 w-3"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </div>
                </button>
              )),
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const getActiveJobFiltersBySectionId = (
  activeJobFilters: ActiveJobFilters | undefined,
  sectionId: string,
) => {
  if (!activeJobFilters) {
    return null;
  }

  switch (sectionId) {
    case "country":
      return activeJobFilters.countryFilters;
    case "seniority":
      return activeJobFilters.seniorityFilters;
    case "stack":
      return activeJobFilters.stackFilters;
    case "techStack":
      return activeJobFilters.techStackFilters;
    case "companies":
      return activeJobFilters.companyFilters;
    default:
      return null;
  }
};
